import { render, staticRenderFns } from "./AppNotificationPermission.vue?vue&type=template&id=e56c6d54&scoped=true"
import script from "./AppNotificationPermission.vue?vue&type=script&lang=js"
export * from "./AppNotificationPermission.vue?vue&type=script&lang=js"
import style0 from "./AppNotificationPermission.vue?vue&type=style&index=0&id=e56c6d54&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e56c6d54",
  null
  
)

export default component.exports